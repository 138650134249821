import React from 'react';
import PropTypes from 'prop-types';
import { DynamicTypography } from './styled';
import { tagStyles, variantStyles } from './styles';

const tagOptions = Object.keys(tagStyles);
const variantOptions = Object.keys(variantStyles);

export const Typography = ({
  tag,
  variant,
  $lineToShow,
  children,
  ...props
}) => (
  <DynamicTypography {...{ tag, variant, $lineToShow, ...props }}>
    {children}
  </DynamicTypography>
);

Typography.propTypes = {
  tag: PropTypes.oneOf(tagOptions),
  variant: PropTypes.oneOf(variantOptions),
  $lineToShow: PropTypes.number,
  children: PropTypes.node
};
