import React from 'react';
import styled, { css } from 'styled-components';
import { tagStyles, variantStyles } from './styles';
import { multilineEllipsis } from '../../../styled/common';

export const DynamicTypography = styled(
  ({ tag, variant, children, hover, styles, ...props }) => {
    const tagVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];
    const Tag = tagVariants.includes(tag) ? tag : 'span';
    return <Tag {...props}>{children}</Tag>;
  }
)`
  transition: color 0.2s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme, color }) => theme.colors.text[color || 'primary']};

  ${({ tag }) => tagStyles[tag || 'default']};
  ${({ variant }) => variantStyles[variant] || css``};
  ${({ variant }) => variantStyles[variant] || css``};
  ${({ $lineToShow }) => $lineToShow && multilineEllipsis($lineToShow)};

  &:hover {
    color: ${({ hover }) => hover};
  }

  ${({ styles }) => styles}
`;
