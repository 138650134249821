import React from 'react';
import {
  PlaceholderCardList,
  PlaceholderSection,
  PlaceholderSectionHeader,
  PlaceholderSectionHeaderMore,
  PlaceholderSectionHeaderTitle
} from './styled';
import { SectionPlaceholderProps } from './types';

export const SectionPlaceholder = ({
  children,
  noHeader = false,
  column = false,
  white = true,
  more = true
}: SectionPlaceholderProps) => {
  const sectionHeader = (
    <PlaceholderSectionHeader>
      <PlaceholderSectionHeaderTitle />
      {more && <PlaceholderSectionHeaderMore />}
    </PlaceholderSectionHeader>
  );

  return (
    <PlaceholderSection white={white}>
      {!noHeader && sectionHeader}
      <PlaceholderCardList column={column}>{children}</PlaceholderCardList>
    </PlaceholderSection>
  );
};
