import styled, { css } from 'styled-components';

export const FlexibleDiv = styled.div`
  display: flex;
`;

export const FlexCenterAligned = styled(FlexibleDiv)`
  align-items: center;
  justify-content: center;
`;

export const FlexColumn = styled(FlexibleDiv)`
  flex-direction: column;
`;

export const FlexBetween = styled(FlexibleDiv)`
  justify-content: space-between;
  align-items: center;
`;

export const ellipsisText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const multilineEllipsis = (linesToShow = 2) => css`
  display: block;
  display: -webkit-box;
  max-height: ${linesToShow * 1.2}em;
  line-height: normal;
  -webkit-line-clamp: ${linesToShow};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EllipsisText = styled.div`
  ${ellipsisText};
  ${({ styles }) => styles}
`;

export const AdsBlock = styled.div`
  position: absolute;
  top: 3px;
  left: 0;
  z-index: 11;
`;
export const FlexCenteredColumn = styled(FlexColumn)`
  align-items: center;
`;

export const customScrollbar = trackMargin => css`
  ::-webkit-scrollbar {
    width: 2px;
    display: flex;

    &-track {
      background: #eae5e1;
      border-radius: 10px;
      margin-bottom: ${trackMargin};
    }

    &-thumb {
      background: ${({ theme }) => theme.colors.text.secondary};
      border-radius: 10px;

      &:hover {
        background: #807c75;
      }
    }
  }
`;
