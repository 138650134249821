import styled, { css, keyframes } from 'styled-components';
import { SectionPlaceholderProps } from './types';

export const placeload = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const animation = css`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeload};
  animation-timing-function: linear;
  background: linear-gradient(to right, #f6f4f1 8%, #eae5e1 18%, #f6f4f1 33%);
  background-size: 1200px 104px;
`;

const placeholderRow = css`
  height: 15px;
  background-color: ${({ theme: { colors } }) => colors.background.secondary};
  border-radius: 4px;
`;

export const PlaceholderSection = styled.div<SectionPlaceholderProps>`
  margin-bottom: 10px;
  padding: 0 10px;
  background-color: ${({ white, theme: { colors } }) =>
    white ? colors.background.primary : 'transparent'};
`;

export const PlaceholderCardList = styled.div<SectionPlaceholderProps>`
  margin-top: 5px;
  display: flex;
  flex-flow: ${({ column }) => (column ? 'column' : 'row')};
  gap: 10px;

  & > div {
    width: 100%;
    margin-right: 0;
  }

  @media screen and (max-width: 315px) {
    & > :nth-child(n + 2) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
    & > :nth-child(n + 3) {
      display: none;
    }
  }
  @media (min-width: ${({ theme }) =>
      theme.tabletBreakpoint}) and (max-width: ${({ theme }) =>
      theme.desktopBreakpoint}) {
    & > :nth-child(n + 4) {
      display: none;
    }
  }
  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    & > :nth-child(n + 5) {
      display: none;
    }
  }
`;

export const PlaceholderSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 14px 0;
`;

export const PlaceholderSectionHeaderTitle = styled.div`
  width: 35%;
  height: 20px;
  ${placeholderRow};
  ${animation};
`;

export const PlaceholderSectionHeaderMore = styled.div`
  width: 10%;
  margin-top: 5px;
  ${placeholderRow};
  ${animation};
`;
